import React, { useEffect, useState } from 'react';
import { ProjectToolbar } from '../../ui/ProjectToolbar/ProjectToolbar';
import { ProjectContainer } from '../ProjectContainer/ProjectContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadDashboard,
  subscribeToDashboardUpdates,
  unsubscribeToDashboardUpdates,
  dashboardSelector,
} from '../../../store/dashboard';
import { settingSelectors, dashboardUrlSaveMessageDisplayed } from '../../../store/setting';
import { Snackbar, Slide, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { authSelectors } from '../../../store/auth';
import { navigate } from '@gatsbyjs/reach-router';
import { useAuth0 } from '@auth0/auth0-react';
import Notification from '../Notification/Notification';

function SlideTransition(props: any) {
  return <Slide {...props} direction="up" />;
}

const snackbarContentStyle = {
  backgroundColor: '#ffacfb',
  color: 'rgb(0, 0, 0)',
  fontWeight: 600,
};

const ProjectDashboard: React.FC<{
  dashboardId: string;
  isUserDashboard?: boolean;
}> = ({ dashboardId, isUserDashboard = false }) => {
  const dispatch = useDispatch();
  const saveUrlMessageDisplayed = useSelector(settingSelectors.saveUrlMessageDisplayed);
  const loading = useSelector(dashboardSelector.loading);
  const error = useSelector(dashboardSelector.error);
  const [openDashboardMsg, setOpenDashboardMsg] = useState(!loading && !saveUrlMessageDisplayed);
  const { isLoading, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    setOpenDashboardMsg(!loading && !saveUrlMessageDisplayed);
  }, [saveUrlMessageDisplayed, loading]);

  const authStateFinalized = useSelector(authSelectors.authStateFinalized);
  const enableAuth = useSelector(authSelectors.enableAuth);

  useEffect(() => {
    if (!authStateFinalized) {
      return;
    }

    if (!enableAuth && !dashboardId) {
      navigate('/');
      return;
    }

    if (!isLoading && !isAuthenticated && !dashboardId) {
      navigate('/hidden');
      return;
    }

    if (dashboardId) {
      dispatch(loadDashboard(dashboardId));
      dispatch(subscribeToDashboardUpdates(dashboardId));
    } else {
      dispatch(loadDashboard(undefined, user?.email));
    }

    return function cleanup() {
      if (dashboardId) {
        dispatch(unsubscribeToDashboardUpdates());
      }
    };
  }, [dashboardId, authStateFinalized, enableAuth]);

  const handleCloseMsg = () => {
    dispatch(dashboardUrlSaveMessageDisplayed(dashboardId));
    setOpenDashboardMsg(false);
  };

  return (
    <Box sx={{ height: '100%' }}>
      {error && <Notification type="error" message={error.message} />}
      <ProjectToolbar isUserDashboard={isUserDashboard} />

      <ProjectContainer />

      {!isUserDashboard && (
        <Snackbar
          open={openDashboardMsg}
          message="Reminder: Bookmark this page."
          onClose={handleCloseMsg}
          autoHideDuration={5000}
          ContentProps={{
            sx: snackbarContentStyle,
          }}
          ClickAwayListenerProps={{
            mouseEvent: false,
          }}
          TransitionComponent={SlideTransition}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseMsg}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      )}
    </Box>
  );
};
export { ProjectDashboard };
