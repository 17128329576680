import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectRender } from '../ProjectRender/ProjectRender';
import { dashboardSelector } from '../../../store/dashboard';
import { projectSelectors } from '../../../store/project';
import { Spinner } from '../../ui';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { ToolbarIconButton } from '../../ui/ToolbarIconButton/ToolbarIconButton';
import { navigate } from '@gatsbyjs/reach-router';
import theme from '../../../gatsby-theme-material-ui-top-layout/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '960px',
    background: theme.palette.background.paper,
    padding: '0px',
  },
}));

const ProjectContainer = () => {
  const classes = useStyles();
  const isLoadingDashboard = useSelector(dashboardSelector.isLoading);
  const isLoadingProject = useSelector(projectSelectors.isLoading);
  const activeDashboardId = useSelector(dashboardSelector.activeDashboardId);
  const activeUserEmailId = useSelector(dashboardSelector.activeUserEmailId);

  const dashboardId = activeDashboardId || activeUserEmailId;
  const projectIds: any = useSelector(dashboardSelector.projects(dashboardId || activeUserEmailId));

  if (isLoadingDashboard || isLoadingProject) {
    return <Spinner />;
  }

  const handleMyProjects = () => {
    if (activeDashboardId) {
      navigate(`/dashboard/${activeDashboardId}`);
    } else {
      navigate(`/dashboard/`);
    }
  };

  const handleTrash = () => {
    navigate(`/trash`);
  };

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" sx={{ marginTop: '10px' }}>
      {projectIds.length > 0 ? (
        <Grid item xs={3}>
          <Grid container spacing={2} direction="column" style={{ marginLeft: '10px' }}>
            <Grid item>
              <ToolbarIconButton aria-label="My projects" onClick={() => handleMyProjects()} title="My projects">
                <AccountBoxIcon fontSize="small" style={{ marginRight: '5px' }} />
                My projects
              </ToolbarIconButton>
            </Grid>
            <Grid item>
              <ToolbarIconButton aria-label="Trash" onClick={() => handleTrash()} title="Trash">
                <DeleteIcon fontSize="small" style={{ marginRight: '5px' }} />
                Trash
              </ToolbarIconButton>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={8}>
        <div className={classes.root}>
          {projectIds.map((id: string) => {
            return <ProjectRender key={id} id={id} />;
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export { ProjectContainer };
