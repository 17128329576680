import React from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Box } from '@mui/material';
import { Logo } from '../../ui/Logo/Logo';
import { createDashboard } from '../../../store/dashboard';
import Button from '@mui/material/Button';

const blackButton = {
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
  borderRadius: '30px',
  border: 0,
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 600,
  lineHeight: 2,
  letterSpacing: '1px',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

const roundPaperStyle = {
  height: '500px',
  width: '400px',
  backgroundColor: '#FFFFFF',
  padding: '25px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '16px',
};

const DashboardInitiaize: React.FC = () => {
  const dispatch = useDispatch();
  const handleCreateDashboard = () => {
    dispatch(createDashboard(''));
    //trackEvent.createProject();
  };

  return (
    <Box
      sx={{
        margin: 2,
        marginTop: '100px',
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Paper elevation={12} sx={roundPaperStyle}>
        <Logo alt="logo" size="large" />
        <h1 style={{ fontSize: '1.5rem' }}>Welcome!</h1>
        <div style={{ fontSize: '1rem', fontWeight: 600 }}>To get started, create your first dashboard.</div>
        <Button sx={blackButton} onClick={handleCreateDashboard} type="submit">
          <span>Create dashboard</span>
        </Button>
      </Paper>
    </Box>
  );
};

export default DashboardInitiaize;
