// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import { ProjectDashboard } from '../components/old-version/ProjectDashboard/ProjectDashboard';
import { ProjectMain } from '../components/old-version/ProjectMain/ProjectMain';
import { HiddenLogin } from '../components/old-version/HiddenLogin/HiddenLogin';
import DashboardInitialize from '../components/old-version/DashboardInitiaize/DashboardInitiaize';

const Dashboard = () => {
  return (
    <AppLayout>
      <Router>
        <ProjectDashboard path="/dashboard" isUserDashboard={true} />
        <ProjectDashboard path="/dashboard/:dashboardId" />
        <ProjectMain path="/dashboard/:activeDashboardId/project/:projectId/workspace/:workspaceId" />
        <HiddenLogin path="/dashboard/hidden" />
        <DashboardInitialize default />
      </Router>
    </AppLayout>
  );
};
export default Dashboard;
