import React, { useState, useEffect } from 'react';
import { Dialog, Button, Paper, TextField, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { collaborateProjectRequest, dashboardSelector } from '../../../store/dashboard';
import { projectService } from '../../../services';

const blackButton = {
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
  borderRadius: '30px',
  border: 0,
  color: '#FFFFFF',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 600,
  lineHeight: 2,
  letterSpacing: '1px',
  fontSize: '1rem',

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

const roundedPaper = {
  height: '500px',
  width: '430px',
  backgroundColor: '#FFFFFF',
  padding: '25px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '16px',
};

const PaperComponent = (props: any) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

type CollaborateDialogProps = {
  open: boolean;
  setOpen: any;
};

const CollaborateDialog: React.FC<CollaborateDialogProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [link, setLink] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [projectId, setProjectId] = useState('');
  // const [dashboardId, setDashboardId] = useState('');
  const activeDashboardId: any = useSelector(dashboardSelector.activeDashboardId);
  const projects: any = useSelector(dashboardSelector.projects(activeDashboardId));

  const handleClose = () => {
    setError(false);
    setOpen(false);
    //trackEvent
  };

  useEffect(() => {
    (async () => {
      if (!error && projectId && projectId.length > 0) {
        const result = await projectService.getProject(projectId);
        if (typeof result === 'undefined') {
          console.error('projectId does not exist in the system.');
          setError(true);
          setErrorMessage('There is an error importing the project, please check the project link is correct.');
        } else {
          setError(false);
          setErrorMessage('');
        }
      }
    })();
  }, [projectId]);

  useEffect(() => {
    if (link.length === 0) {
      setError(true);
      setErrorMessage('Please paste the project link');
      return;
    }
    if (!link.includes('/project/')) {
      setError(true);
      setErrorMessage('There is an error importing the project, please check the project link is correct.');
      return;
    }
  }, [link]);

  const captureLink = (event: any) => {
    setLink(event.target.value);
    if (event.target.value.length === 0) return;
    performValidations(event.target.value);
  };

  const performValidations = (link: string) => {
    validateApplnContext(link);
    const projectId = getId(link, '/project/');
    validateCollaborateEligibility(projectId);
    validateProjectId(projectId);
  };

  const getId = (link: string, separator: string) => {
    const splitEntries = link.split(separator);
    return splitEntries[1];
  };

  const validateApplnContext = (link: string) => {
    const currentUrl = window.location.href;
    const applnContext = link.split('/project/')[0];
    const currentApplnContext = currentUrl.split('/dashboard/')[0];
    if (currentApplnContext !== applnContext) {
      console.error('appl context doesnt match');
      setError(true);
      setErrorMessage('There is an error importing the project, please check the project link is correct.');
      return;
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const validateCollaborateEligibility = (projectId: string) => {
    if (projects.includes(projectId)) {
      setError(true);
      setErrorMessage('This has already been imported.');
      return;
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const validateProjectId = (projectId: string) => {
    setProjectId(projectId);
  };

  const handleCollaborateProject = () => {
    if (link.length === 0) {
      setError(true);
      setErrorMessage('Please paste the project link');
      return;
    }
    if (error) return;

    setOpen(false);
    const projectId = getId(link, '/project/');
    const currentUrl = window.location.href;
    const currentDashboardId = getId(currentUrl, '/dashboard/');

    dispatch(collaborateProjectRequest(currentDashboardId, projectId));
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
      <Box
        height="90%"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        component="section"
        m={2}
        style={{ marginTop: '100px' }}
      >
        <Paper elevation={12} sx={roundedPaper}>
          <div style={{ fontSize: '1.25rem' }}>
            To collaborate an existing project, please paste the project link below:
          </div>
          <TextField
            autoFocus
            variant="outlined"
            color="primary"
            margin="dense"
            id="link"
            label="https://"
            type="url"
            fullWidth
            required={true}
            multiline={true}
            rows={4}
            onChange={(event) => {
              captureLink(event);
            }}
          />
          {error && (
            <span>
              <ErrorIcon fontSize="small" style={{ marginBottom: '-6px', marginRight: '15px' }} />
              <span
                style={{
                  fontSize: '.8rem',
                  fontWeight: 600,
                  color: 'red',
                }}
              >
                {errorMessage}
              </span>
            </span>
          )}

          <Button type="submit" onClick={handleCollaborateProject} sx={blackButton}>
            Collaborate Project
          </Button>
        </Paper>
      </Box>
    </Dialog>
  );
};

export { CollaborateDialog };
