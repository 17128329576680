import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { AppBar, Toolbar, Popper, Grow, Paper, MenuList, MenuItem, Snackbar, IconButton, Slide } from '@mui/material';
import grey from '@mui/material/colors/grey';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
import { ToolbarIconButton } from '../ToolbarIconButton/ToolbarIconButton';
import { useDispatch, useSelector } from 'react-redux';
import { createProject } from '../../../store/project';
import { dashboardSelector, importProjectRequest } from '../../../store/dashboard';
import { useAuth0 } from '@auth0/auth0-react';
import JSZip from 'jszip';
import { CollaborateDialog } from '../CollaborateDialog/CollaborateDialog';
import { useSnackbar } from 'notistack';

function SlideTransition(props: any) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles(() => ({
  gutters: {
    padding: '4px 12px',
  },
  titleContainer: {
    overflow: 'hidden',
    alignSelf: 'center',
    paddingRight: '30px',
    color: '#000000',
  },
  snackbarRoot: {
    backgroundColor: '#ffacfb',
    color: 'rgb(0, 0, 0)',
    fontWeight: 600,
  },
}));

const menuItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'auto',
  zIndex: 4888,
  position: 'inherit',
};

const ProjectToolbar: React.FC<{ isUserDashboard: boolean }> = ({ isUserDashboard }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const anchorRef = React.useRef<any>(null);
  const zipInputRef = useRef<any>(null);
  const [open, setOpen] = React.useState(false);

  const [openCollaborateProjectConfirmation, setOpenCollaborateProjectConfirmation] = useState(false);

  const [projectImportErrorMsg, setProjectImportErrorMsg] = useState('');
  const [projectImportError, setProjectImportError] = useState(false);
  const dashboardId = useSelector(dashboardSelector.activeDashboardId);
  const { user } = useAuth0();
  const dashboardError: any = useSelector(dashboardSelector.error);

  useEffect(() => {
    if (typeof dashboardError !== 'undefined' && dashboardError.message.length > 0) {
      setProjectImportErrorMsg('please provide correct zip file.');
      setProjectImportError(true);
    }
  }, [dashboardError]);

  const handleNewProject = (event: any) => {
    event.stopPropagation();
    if (dashboardId) {
      dispatch(createProject(dashboardId));
    } else {
      dispatch(createProject(undefined, user?.email));
    }
    handleClose();
    setOpen(false);
  };

  const handleAddCircleRoundedIconClick = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleCollaborateProject = (event: any) => {
    event.stopPropagation();
    setOpenCollaborateProjectConfirmation(true);
    handleClose();
    setOpen(false);
  };

  const handleImportProject = () => {
    const fileInput: any = document.getElementById('zipFile');
    fileInput.addEventListener('change', async function () {
      const file = fileInput.files[0];
      if (typeof file === 'undefined') {
        return null;
      }
      try {
        if (file.type !== 'undefined' && !/zip/.test(file.type)) {
          throw new Error('file not supported');
        }
        const files: string[] = await getZipFilesContent(file);
        const currentUrl = window.location.href;
        const currentDashboardId = getId(currentUrl, '/dashboard/');
        dispatch(importProjectRequest(currentDashboardId, files));
      } catch (e: any) {
        setProjectImportErrorMsg('File type not supported.');
        setProjectImportError(true);
        enqueueSnackbar('File type not supported.', { variant: 'error' });
      }
    });
    fileInput.click();
    handleClose();
    setOpen(false);
  };

  async function getZipFilesContent(data: any): Promise<string[]> {
    const zipContent: string[] = [];
    const promises: any[] = [];
    const zip = await JSZip.loadAsync(data);
    zip.forEach(async (relativePath: any, file: any) => {
      const promise = file.async('string');
      promises.push(promise);
      zipContent.push(
        JSON.stringify({
          name: relativePath,
          content: await promise,
        })
      );
    });
    await Promise.all(promises);

    return zipContent;
  }

  const getId = (link: string, separator: string) => {
    const splitEntries = link.split(separator);
    return splitEntries[1];
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuItems = [
    {
      id: 1,
      label: 'New Project',
      title: 'New Project',
      onClick: handleNewProject,
      ariaLabel: 'New Document',
    },
    {
      id: 2,
      label: 'Import Project',
      title: 'please upload zip file',
      onClick: handleImportProject,
      ariaLabel: 'Import Project',
    },
    {
      id: 3,
      label: 'Collaborate Project',
      title: 'Collaborate Project',
      onClick: handleCollaborateProject,
      ariaLabel: 'Collaborate Project',
    },
  ];

  const handleCloseMsg = () => {
    setProjectImportError(false);
  };

  return (
    <>
      <AppBar position="static" color="inherit" sx={{ backgroundColor: grey[200], position: 'relative' }}>
        <Toolbar variant="dense" disableGutters={true} style={{ width: '50%', margin: '0 auto' }}>
          <div className={classes.titleContainer}>
            <ToolbarIconButton
              aria-label="Project menu"
              onClick={handleAddCircleRoundedIconClick}
              title="Project options"
              ref={anchorRef}
            >
              <AddCircleRoundedIcon fontSize="small" color="secondary" />
              <h3 style={{ marginLeft: '4px' }}>Add</h3>
            </ToolbarIconButton>
          </div>
        </Toolbar>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {menuItems.map(({ title, onClick, label, ariaLabel }) => {
                      if (title === 'Collaborate Project' && isUserDashboard) {
                        return null;
                      }

                      return (
                        <MenuItem
                          onClick={onClick}
                          sx={menuItemStyle}
                          classes={{
                            gutters: classes.gutters,
                          }}
                          key={label}
                          title={title}
                          aria-label={ariaLabel}
                        >
                          <span>{label}</span>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </AppBar>
      <CollaborateDialog open={openCollaborateProjectConfirmation} setOpen={setOpenCollaborateProjectConfirmation} />
      <span>
        <input
          type="file"
          name="files[]"
          id="zipFile"
          hidden
          accept="zip,application/zip,application/x-zip,application/x-zip-compressed"
          ref={zipInputRef}
        />
      </span>
      <Snackbar
        open={projectImportError}
        message={projectImportErrorMsg}
        onClose={handleCloseMsg}
        autoHideDuration={2000}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot,
          },
        }}
        ClickAwayListenerProps={{
          mouseEvent: false,
        }}
        TransitionComponent={SlideTransition}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseMsg}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export { ProjectToolbar };
